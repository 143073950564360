<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
          class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.outsource_party") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
              size="mini"
              :placeholder="$t('message.search')"
              prefix-icon="el-icon-search"
              v-model="filterForm.search"
              clearable
          ></el-input>
        </div>
      </div>

      <div
          class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end"
      >
        <el-button
            size="mini"
            @click="drawerCreate = true"
            icon="el-icon-circle-plus-outline"
        >
          {{ $t("message.create") }}
        </el-button>

        <crm-column-settings
            :columns="columns"
            :modelName="'outsource'"
            @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>

    <table
        style="background: #fff"
        class="table table-bordered table-hover"
        v-loading="loadingData"
    >
      <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
      ></crm-pagination>
      <thead>
      <tr>
        <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :column="columns.partner_clinic"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :column="columns.courier"
            :sort="sort"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :sort="sort"
            :column="columns.created_at"
            @c-change="updateSort"
        ></crm-th>
        <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
        ></crm-th>
      </tr>
      <tr>
        <th v-if="columns.id.show">
          <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
          ></el-input>
        </th>
        <th v-if="columns.partner_clinic.show">
          <el-select
              class="autoInput"
              v-model="filterForm.partner_clinic_id"
              filterable
              clearable
              :placeholder="$t('message.partner_clinic')"
          >
            <el-option
                v-for="item in partnerClinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </th>
        <th v-if="columns.courier.show">
          <el-select
              class="autoInput"
              v-model="filterForm.courier_id"
              filterable
              clearable
              :placeholder="$t('message.couriers')"
          >
            <el-option
                v-for="item in couriers"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </th>
        <th v-if="columns.created_at.show">
          <el-date-picker
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
              v-model="filterForm.created_at"
              :placeholder="columns.created_at.title"
              size="mini"
          >
          </el-date-picker>
        </th>
        <th v-if="columns.settings.show"></th>
      </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr v-for="item in list" :key="item.id" class="cursor-pointer">
          <td v-if="columns.id.show">{{ item.id }}</td>
          <td v-if="columns.partner_clinic.show">
            {{ item.partner_clinic ? item.partner_clinic.name : '' }}
          </td>
          <td v-if="columns.courier.show">
            {{ item.courier ? item.courier.name : '' }}
          </td>
          <td v-if="columns.created_at.show">
            {{ item.created_at }}
          </td>
          <td v-if="columns.settings.show" class="settings-td">
            <el-button
                round
                @click="viewOpen(item)"
                size="mini"
                type="primary"
                icon="el-icon-view"
            >{{ $t("message.services") }}</el-button
            >
            <el-button @click="edit(item)" type="primary" icon="el-icon-edit">{{
                $t("message.edit")
              }}</el-button>
            <el-button @click="print(item.id)" type="success">{{
                $t("message.print")
              }}</el-button>
            <el-button @click="deleteParty(item.id)" type="danger">{{
                $t("message.delete")
              }}</el-button>
          </td>
        </tr>
      </transition-group>
    </table>

    <el-dialog
      :title="$t('message.outsource_party')"
      :append-to-body="true"
      :visible.sync="servicesView"
      width="50%"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <el-card class="box-card">
            <el-table :data="[tablePartyData]" style="width: 100%">
              <el-table-column :label="$t('message.partner_clinic')" prop="partner_clinic" width="300" align="center"></el-table-column>
              <el-table-column :label="$t('message.courier')" prop="courier" width="150" align="center"></el-table-column>
              <el-table-column :label="$t('message.services_count')" prop="services_count" width="150" align="center"></el-table-column>
              <el-table-column :label="$t('message.created_at')" prop="created_at"  align="center"></el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="24" class="mt-2">
          <el-card class="box-card">
            <el-table :data="services" style="width: 100%">
              <el-table-column prop="order_id" :label="$t('message.order')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_service.order_id }}
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="$t('message.service_name')" width="200" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_service.name }}
                </template>
              </el-table-column>
              <el-table-column prop="price" :label="$t('message.price2')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_service.price | formatMoney }}
                </template>
              </el-table-column>
              <el-table-column prop="code" :label="$t('message.code')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_service.code }}
                </template>
              </el-table-column>
              <el-table-column prop="lifetime" label="Срок услуги" align="center">
                <template slot-scope="scope">
                  {{ scope.row.order_service.lifetime }}
                </template>
              </el-table-column>
              <el-table-column prop="file" width="150" :label="$t('message.file')">
                <template slot-scope="scope">
                  <el-upload
                      class="upload-demo"
                      :action="uploadAction"
                      :headers="uploadHeaders"
                      :data="{
                        party_id: party_id,
                        order_service_id: scope.row.order_service.order_service_id
                      }"
                      :file-list="fileList"
                      :show-file-list="false"
                      :on-remove="handleRemove"
                      :on-success="(response, file) => handleSuccess(response, file, scope.row)"
                      :limit="1"
                  >
                    <el-button type="primary">{{ $t("message.file_upload") }}</el-button>
                  </el-upload>
                  <div class="el-upload__text" v-if="!fileExist(scope.row)">
                    <em>{{ $t("message.dont_have_file") }}</em>
                  </div>
                  <div class="el-upload__text" v-else>
                    <em>{{ $t("message.file_uploaded") }}</em>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </el-col>
        <el-col :span="24" class="mt-2 mb-1">
          <el-button @click="print(party_id)" type="success" class="print-button">{{
              $t("message.print")
            }}</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-drawer
        :title="$t('message.new_type_expence')"
        :visible.sync="drawerCreate"
        size="60%"
        :drawer="drawerCreate"
        @close="reloadIfChanged"
    >
      <div>
        <crm-create
            :reloadModel="reopenUpdate"
            @c-close="reloadIfChanged('drawerCreate')"
        ></crm-create>
      </div>
    </el-drawer>
    <el-drawer
        :visible.sync="drawerUpdateParty"
        size="60%"
        :wrapperClosable="true"
        :drawer="drawerUpdateParty"
        @closed="reloadIfChanged()"
    >
      <crm-update
          @c-close="closeDrawer"
          :party="selectedModel"
          :open="drawerUpdateParty"
          drawer="drawerOrderUpdate"
      ></crm-update>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createOutsourceParty.vue";
import CrmUpdate from "./components/updateOutsourceParty.vue";
import CrmShow from "./components/Parts/components/crm-show";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import {i18n} from "@/utils/i18n";
import {isEmpty} from "lodash";
export default {
  mixins: [list, drawer],
  name: "outsourceParty",
  components: {
    CrmShow,
    CrmCreate,
    CrmUpdate,
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      reopenUpdate: false,
      drawerUpdate: false,
      drawerUpdateParty: false,
      servicesView: false,
      reopenShow: false,
      selected_id: null,
      excel_data: [],
      excel_fields: {},
      loadingData: false,
      edit_open: false,
      tablePartyData: [],
      services: [],
      party_id: null,
      selectedModel: null,
      fileList: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "outsourceParty/list",
      columns: "outsourceParty/columns",
      pagination: "outsourceParty/pagination",
      filter: "outsourceParty/filter",
      sort: "outsourceParty/sort",

      partnerClinics: 'partnerClinic/inventory_all',
      couriers: "couriers/inventory",
    }),
    uploadAction() {
      return this.base_url + 'outsource-party/storeFile';
    },
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.$store.getters.token,
      };
    },
  },
  async created() {},
  async mounted() {
    if (!this.couriers || this.couriers && this.couriers.length === 0) {
      await this.loadCouriers();
    }

    if (this.partnerClinics && this.partnerClinics.length === 0)
      await this.loadPartnerClinics();
  },
  watch: {
    columns: {
      handler: function () {},
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      updateList: "outsourceParty/index",
      updateSort: "outsourceParty/updateSort",
      updateFilter: "outsourceParty/updateFilter",
      updateColumn: "outsourceParty/updateColumn",
      updatePagination: "outsourceParty/updatePagination",

      empty: "outsourceParty/empty",
      delete: "outsourceParty/destroy",
      refreshData: "outsourceParty/refreshData",
      editModel: "outsourceParty/show",

      loadCouriers: "couriers/inventory",
      loadPartnerClinics: "partnerClinic/inventoryAll",
    }),
    handleRemove(file, fileList) {
    },
    handleSuccess(response, file, party_service) {
      this.$set(party_service, 'file', response.data.file);
    },
    fileExist(party_service) {
      if (isEmpty(party_service.file)) {
        return false;
      } else {
        return true;
      }
    },
    fetchData() {
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query)
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
      }
    },
    refresh() {
      this.refreshData()
          .then((res) => {
            this.filterForm = JSON.parse(JSON.stringify(this.filter));
          })
          .catch((err) => {});
    },
    async edit(model) {
      this.selectedModel = model;
      this.drawerUpdateParty = true;
    },
    async viewOpen(model) {
      this.tablePartyData = {
        partner_clinic: model.partner_clinic.name,
        courier: model.courier ? model.courier.name : null,
        services_count: model.party_services.length,
        created_at: model.created_at,
      };
      this.services = model.party_services;
      this.party_id = model.id;
      this.servicesView = true;
    },
    print(id) {
      this.waitingPrint = true;
      let vm = this;
      var jqxhr = $.get(process.env.VUE_APP_URL + `outsource-party/print?id=${id}`, function () { })
          .done(function (res) {
            vm.waitingPrint = false;
            const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            WinPrint.document.write(res);
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(() => {
              WinPrint.print();
              WinPrint.close();
            }, 1000);
          })
          .fail(function () {
            alert(i18n.t("message.cannot_print"));
            vm.waitingPrint = false;
          })
    },
    emptyModel() {
      this.empty();
    },
    deleteParty(id) {
      this.delete(id)
        .then((res) => {
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
        });
    },
  },
};
</script>
<style>
.print-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
